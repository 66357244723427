import { Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/_core/helpers/CustomValidators.helpers';
import { CONTROL_TYPES, ControlError, GSControl } from 'src/app/_core/models/FormControls';

export enum DECLINE_REASON_CONTROLS {
  DECLINE_REASON = 'declineReason',
  DECLINE_REASON_DETAILS = 'declineReasonDetails',
}

export const ChampionDeclineReasonForm: GSControl = new GSControl({
  controlType: CONTROL_TYPES.FORM_GROUP,
  controls: {
    [DECLINE_REASON_CONTROLS.DECLINE_REASON]: new GSControl({
      label: 'SHARED.MODALS.DECLINE_REASON_MODAL.DECLINE_REASON_MODAL_FORM.LABELS.REASON',
      placeholder: 'SHARED.MODALS.DECLINE_REASON_MODAL.DECLINE_REASON_MODAL_FORM.PLACEHOLDERS.REASON',
      validators: [Validators.required],
      errors: [new ControlError('required', 'SHARED.MODALS.DECLINE_REASON_MODAL.DECLINE_REASON_MODAL_FORM.ERRORS.REASON_REQUIRED')],
    }),
    [DECLINE_REASON_CONTROLS.DECLINE_REASON_DETAILS]: new GSControl({
      label: 'SHARED.MODALS.DECLINE_REASON_MODAL.DECLINE_REASON_MODAL_FORM.LABELS.REASON_DETAILS',
      placeholder: 'SHARED.MODALS.DECLINE_REASON_MODAL.DECLINE_REASON_MODAL_FORM.PLACEHOLDERS.REASON_DETAILS',
      validators: [CustomValidators.maxLength(200)],
      errors: [
        new ControlError('required', 'SHARED.MODALS.DECLINE_REASON_MODAL.DECLINE_REASON_MODAL_FORM.ERRORS.REASON_DETAILS_REQUIRED'),
        new ControlError('maxlength', 'SHARED.MODALS.DECLINE_REASON_MODAL.DECLINE_REASON_MODAL_FORM.ERRORS.REASON_DETAILS_MAX_LENGTH'),
      ],
    }),
  },
});
