<div class="modal-container">
  <div class="d-flex justify-content-between w-100">
    <div class="my-modal-header text-right" style="width: 60% !important">Reason of declining</div>
    <div class="d-flex justify-content-end mb-4" style="width: 40% !important">
      <a (click)="emitFalse()"><img class="pointer" src="assets/icons/clear-icon.svg" /></a>
    </div>
  </div>

  <form [formGroup]="championDeclineReasonForm" class="w-100 bg-white position-relative px-lg-4" autocomplete="off" list="autocompleteOff">
    <!-- Reason -->
    <div class="admin-input mb-2">
      <div class="label">{{ declineReason.label | translate }} *</div>
      <mat-form-field appearance="outline">
        <mat-select [id]="declineReason.key" [formControlName]="declineReason.key">
          <mat-option [id]="i" *ngFor="let reason of declineReasons; let i = index" [value]="reason"
            >{{ reason.description | translate }}
          </mat-option>
        </mat-select>
        <mat-error *ngFor="let err of declineReason.controlErrors">
          <mat-error *ngIf="declineReason.hasError(err.type)">
            {{ err.text | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Reason details -->
    <div class="admin-textarea" *ngIf="viewReasonDetails">
      <mat-form-field appearance="outline">
        <textarea
          cdkTextareaAutosize
          cdkAutosizeMaxRows="10"
          matInput
          rows="2"
          [id]="declineReasonDetails.key"
          [formControlName]="declineReasonDetails.key"
        ></textarea>
        <mat-error *ngFor="let err of declineReasonDetails.controlErrors">
          <mat-error *ngIf="declineReasonDetails.hasError(err.type)">
            {{ err.text | translate }}
          </mat-error>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="d-flex justify-content-end">
      <button
        mat-button
        [disableRipple]="true"
        class="px-2 flex-all-center button-height-28 mat-main-button action-button w-100"
        (click)="emitTrue()"
      >
        Submit
      </button>
    </div>
  </form>
</div>
