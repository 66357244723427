import { DUE_DILIGENCE_LEVEL } from '../../../../_core/constants/OrganizationFormControls';
import { PaginatedResponse, UUIDName } from '../../../../_core/models/GenericObject';
import { Media } from '../../../../_core/models/FormControls';
import { DeclineReasons, ENGAGEMENT_MONTHS, TESTIMONIAL_EXPIRATION_OPTIONS } from 'src/app/_core/constants/Champion';

export class Champion {
  champion?: UUIDName;
  dueDiligenceLevel: DUE_DILIGENCE_LEVEL;
  grantsAwarded: number;
  grantsAwardedCurrency: UUIDName;
  quote: string;
  testimonial: string;
  videoPresentation: Media;
  historyDueDiligence: string;
  dueDiligenceReport: boolean;
  dueDiligenceFile: Media;
  impactStory: string;
  topRisks: string;

  constructor(champion: IChampion) {
    this.champion = new UUIDName(champion.uuid, '');
    this.dueDiligenceLevel = champion.dueDiligenceLevel;
    this.grantsAwarded = champion.grantsAwardedNumber;
    this.grantsAwardedCurrency = champion.currency;
    this.quote = champion.quote;
    this.testimonial = champion.testimonial;
    this.videoPresentation = champion.video;
    this.historyDueDiligence = champion.history;
    this.dueDiligenceReport = !!champion.document;
    this.dueDiligenceFile = champion.document;
    this.impactStory = champion.impactStory;
    this.topRisks = champion.topRisks;
  }
}

export class Champion2 {
  organizationEngagement: ChampionEngagement;
  grantsAwarded: number;
  grantsAwardedCurrency: UUIDName;
  testimonial: string;
  championMedia: ChampionMedia;
  testimonialVisibility: UUIDName;
  entityName?: string;
  organizationName: string;
  organizationChampionLinkUuid: string;

  constructor(champion: IChampionResponse) {
    const selectedEngagementMonth = ENGAGEMENT_MONTHS.find(
      (engagement) => engagement.uuid === champion.championLinkedEntityData.engagementMonth.toString()
    );
    const selectedEngagementYear = new UUIDName<string>(champion.championLinkedEntityData.engagementYear.toString(), '');
    this.organizationEngagement = new ChampionEngagement(selectedEngagementYear, selectedEngagementMonth);
    this.testimonial = champion.championLinkedEntityData.testimonial;
    this.entityName = champion.championLinkedEntityData.entityName;
    this.championMedia = new ChampionMedia(champion.championLinkedEntityData.photo, champion.championLinkedEntityData.video);
    this.testimonialVisibility = TESTIMONIAL_EXPIRATION_OPTIONS.find((o) => o.uuid === champion.championLinkedEntityData.validityMonth.toString());
    this.organizationName = champion.organisationName;
    this.organizationChampionLinkUuid = champion.organisationChampionLinkUuid;
  }
}

export interface LinkChampionPayload {
  organisationUuid?: string;
  projectUuid?: string;
  portfolioUuid?: string;
  championLinkedEntityData: IChampion;
}

export interface LinkChampionPayload2 {
  organisationUuid?: string;
  organisationChampionLinkUuid?: string;
  championLinkedEntityData: IChampionPayload2 | IChampionDraftPayload;
}

export interface IChampion {
  currency: UUIDName;
  document: Media;
  dueDiligenceLevel: DUE_DILIGENCE_LEVEL;
  email?: string;
  grantsAwardedNumber: number;
  quote: string;
  history: string;
  impactStory: string;
  testimonial: string;
  topRisks: string;
  uuid: string;
  video: Media;
}

export interface IChampionPayload2 {
  uuid: string;
  testimonial: string;
  engagementYear: number;
  engagementMonth: number;
  validityMonth: number;
  photoOrVideo: Media;
}

export interface IChampionLinkedEntityResponse {
  uuid: string;
  testimonial: string;
  engagementYear: number;
  engagementMonth: number;
  validityMonth: number;
  photo: Media;
  video: Media;
  entityName?: string;
}

export interface IChampionDraftPayload {
  uuid?: string;
  testimonial?: string;
  engagementYear?: number;
  engagementMonth?: number;
  validityMonth?: number;
  photoOrVideo?: {
    uuid: string;
  };
}

export interface IChampion2 {
  organisationUuid: string;
  organisationChampionLinkUuid: string;
  championLinkedEntityData: IChampionPayload2;
}

export interface IChampionResponse {
  organisationUuid: string;
  organisationChampionLinkUuid: string;
  organisationName: string;
  championLinkedEntityData: IChampionLinkedEntityResponse;
}

export class ChampionMedia {
  image: Media;
  video: Media;

  constructor(image: Media, video: Media) {
    this.image = image;
    this.video = video;
  }
}

export class ChampionEngagement {
  engagementStartYear: UUIDName;
  engagementStartMonth: UUIDName;

  constructor(engagementStartYear: UUIDName, engagementStartMonth: UUIDName) {
    this.engagementStartYear = engagementStartYear;
    this.engagementStartMonth = engagementStartMonth;
  }
}

export interface ChampionDeclineReasonDto {
  declineReason: string;
  declineReasonDetails: string;
}

export class ChampionDeclineReason {
  declineReason: string;
  declineReasonDetails: string;

  constructor(championDeclineReason: ChampionDeclineReasonDto) {
    this.declineReason = championDeclineReason.declineReason;
    this.declineReasonDetails = championDeclineReason.declineReasonDetails;
  }
}

export interface DecliningReasonDto {
  reason: DeclineReasons;
  description: string;
}

export class DecliningReason {
  reason: DeclineReasons;
  description: string;

  constructor(decliningReason: DecliningReasonDto) {
    this.reason = decliningReason.reason;
    this.description = decliningReason.description;
  }
}

export interface ChampionTestimonialPaginatedResponse<T> extends PaginatedResponse<T> {
  actionItemsCount: number;
}
