import Urls from './Urls';
import { Tab } from '../models/Tab';
import { ModulePrivileges } from './Privileges';

import { FILTER_NAME } from './Filters/FilterTypes';
import { Role } from './User';
import { ENTITY_TYPE, EntityStatus } from './CompanyOrFirm';
import { ChampionStatus } from './Champion';

export const HEADER_LINKS: Tab[] = [
  {
    name: 'GENERAL.MENU.PROJECTS',
    link: `${Urls.CATALOG}/${Urls.GIVING}/${Urls.PROJECTS}`,
    icon: null,
    specialStyle: false,
    order: 0,
    privileges: ModulePrivileges[Urls.GIVING],
  },
  {
    name: 'GENERAL.MENU.ORGANISATIONS',
    link: `${Urls.CATALOG}/${Urls.GIVING}/${Urls.ORGANIZATIONS}`,
    icon: null,
    specialStyle: false,
    order: 1,
    privileges: ModulePrivileges[Urls.GIVING],
  },
  {
    name: 'GENERAL.MENU.PORTFOLIOS',
    link: `${Urls.CATALOG}/${Urls.GIVING}/${Urls.PORTFOLIOS}`,
    icon: null,
    specialStyle: false,
    order: 2,
    privileges: ModulePrivileges[Urls.GIVING],
  },
];

export const CATALOG_LINKS: Tab[] = [
  {
    name: 'GENERAL.MENU.GIVING',
    link: `${Urls.GIVING}/${Urls.PROJECTS}`,
    privileges: ModulePrivileges[Urls.GIVING],
    urls: {
      [Urls.CATALOG]: true,
    },
  },
  {
    name: 'GENERAL.MENU.ORGANISATIONS',
    link: `${Urls.GIVING}/${Urls.ORGANIZATIONS}`,
    privileges: ModulePrivileges[Urls.GIVING],
    entityType: ENTITY_TYPE.ORGANISATION,
    order: 0,
    urls: {
      [Urls.HOMEPAGE]: true,
      [Urls.CHAMPIONS]: true,
      [Urls.MY_CHAMPIONED]: true,
      [Urls.MY_FAVORITES]: true,
    },
  },
  {
    name: 'GENERAL.MENU.PROJECTS',
    link: `${Urls.GIVING}/${Urls.PROJECTS}`,
    privileges: ModulePrivileges[Urls.GIVING],
    entityType: ENTITY_TYPE.PROJECT,
    order: 1,
    urls: {
      [Urls.CLOSE_TO_CLOSING]: true,
      [Urls.HOMEPAGE]: true,
      [Urls.CHAMPIONS]: true,
      [Urls.MY_FAVORITES]: true,
      [Urls.MY_CHAMPIONED]: true,
    },
  },
  {
    name: 'GENERAL.MENU.PORTFOLIOS',
    link: `${Urls.GIVING}/${Urls.PORTFOLIOS}`,
    privileges: ModulePrivileges[Urls.GIVING],
    entityType: ENTITY_TYPE.PORTFOLIO,
    order: 2,
    urls: {
      [Urls.HOMEPAGE]: true,
      [Urls.CHAMPIONS]: true,
      [Urls.MY_CHAMPIONED]: true,
    },
  },
];

export enum AdminTabRole {
  HELP_VIDEOS = 'HELP_VIDEOS',
}

export const SETTINGS_TABS: Tab[] = [
  {
    name: 'SETTINGS.MENU.SETTINGS',
    children: [
      {
        name: 'SETTINGS.MENU.PROFILE',
        link: `${Urls.USER}/${Urls.USER_ACCOUNT}`,
        privileges: ModulePrivileges[Urls.USER_ACCOUNT],
      },
      {
        name: 'SETTINGS.MENU.FAVORITES',
        link: `${Urls.USER}/${Urls.MY_FAVORITES}`,
        privileges: ModulePrivileges[Urls.USER_ACCOUNT],
      },
      {
        name: 'SETTINGS.MENU.CHAMPIONED',
        link: `${Urls.USER}/${Urls.MY_CHAMPIONED}`,
        privileges: ModulePrivileges[Urls.MY_CHAMPIONED],
      },
      {
        name: 'HOME.MENU.GROUPS',
        link: Urls.GROUPS,
        privileges: ModulePrivileges[Urls.GROUPS],
        children: [
          {
            name: 'HOME.MENU.MY_GROUPS',
            link: `${Urls.GROUPS}/${Urls.LIST}/${Urls.MY_GROUPS}`,
            privileges: ModulePrivileges[Urls.GROUPS],
          },
          {
            name: 'HOME.MENU.ALL_GROUPS',
            link: `${Urls.GROUPS}/${Urls.LIST}/${Urls.ALL_GROUPS}`,
            privileges: ModulePrivileges[Urls.GROUPS],
          },
        ],
      },
    ],
  },
  {
    name: 'SETTINGS.MENU.ADMIN',
    children: [
      {
        name: 'SETTINGS.MENU.ORGANIZATIONS',
        link: Urls.ORGANIZATIONS,
        privileges: ModulePrivileges[Urls.ORGANIZATIONS],
      },
      {
        name: 'SETTINGS.MENU.PROJECTS',
        link: Urls.PROJECTS,
        privileges: ModulePrivileges[Urls.PROJECTS],
      },
      {
        name: 'SETTINGS.MENU.USERS',
        link: Urls.USERS_PERMISSIONS,
        privileges: ModulePrivileges[Urls.USERS_PERMISSIONS],
      },
      {
        name: 'SETTINGS.MENU.PORTFOLIOS',
        link: Urls.PORTFOLIOS,
        privileges: ModulePrivileges[Urls.PORTFOLIOS],
      },
      {
        name: 'SETTINGS.MENU.CHAMPIONS',
        link: Urls.CHAMPIONS,
        privileges: ModulePrivileges[Urls.CHAMPIONS],
      },
      {
        name: 'SETTINGS.MENU.GLOBAL_SETTINGS',
        link: Urls.GLOBAL_SETTINGS,
        privileges: ModulePrivileges[Urls.GLOBAL_SETTINGS],
      },
      {
        name: 'SETTINGS.MENU.HELP_VIDEOS',
        // role: AdminTabRole.HELP_VIDEOS,
        link: Urls.HELP_VIDEOS,
        privileges: ModulePrivileges[Urls.ADMIN],
      },
    ],
  },
];

export enum UserTab {
  MY_INFO = 'My Info',
  SETTINGS = 'Settings',
}

export const USER_TABS: Tab[] = [
  {
    name: UserTab.MY_INFO,
    link: `${Urls.USER_ACCOUNT}/${Urls.MY_INFO}`,
  },
  {
    name: UserTab.SETTINGS,
    link: `${Urls.USER_ACCOUNT}/${Urls.USER_SETTINGS}`,
  },
];

export enum OrganizationTabs {
  SUMMARY = 'CATALOG.DETAILS.STEPS.SUMMARY',
  TEAM = 'CATALOG.DETAILS.STEPS.TEAM',
  FINANCIALS = 'CATALOG.DETAILS.STEPS.FINANCIALS',
  PROJECTS = 'CATALOG.DETAILS.STEPS.PROJECTS',
  CHAMPION = 'CATALOG.DETAILS.STEPS.CHAMPION',
  QUESTIONS = 'CATALOG.DETAILS.STEPS.QUESTIONS',
  DOCUMENTS = 'CATALOG.DETAILS.STEPS.DOCUMENTS',
}

export const ORGANIZATION_TABS_INFO: Tab[] = [
  {
    name: OrganizationTabs.SUMMARY,
    icon: 'fas fa-th fa-lg',
    link: `/${Urls.SUMMARY_TAB}`,
    id: 'org-summary-tab',
    visibility: true,
  },
  {
    name: OrganizationTabs.TEAM,
    icon: 'fas fa-users fa-lg',
    link: `/${Urls.TEAM_TAB}`,
    id: 'org-team-tab',
    visibility: true,
  },
  {
    name: OrganizationTabs.FINANCIALS,
    icon: 'fas fa-chart-line fa-lg',
    link: `/${Urls.FINANCIALS_TAB}`,
    id: 'org-financials-tab',
    visibility: true,
  },
  {
    name: OrganizationTabs.PROJECTS,
    icon: 'far fa-file-alt fa-lg',
    link: `/${Urls.PROJECTS_TAB}`,
    id: 'org-projects-tab',
    visibility: true,
  },
  {
    name: OrganizationTabs.CHAMPION,
    icon: 'fas fa-hand-holding-usd fa-lg',
    link: `/${Urls.CHAMPION_TAB}`,
    id: 'org-champions-tab',
    visibility: true,
  },
  {
    name: OrganizationTabs.DOCUMENTS,
    icon: 'fas fa-file-pdf fa-lg',
    link: `/${Urls.DOCUMENTS_TAB}`,
    id: 'org-documents-tab',
    visibility: true,
  },
];

export enum ProjectTabs {
  SUMMARY = 'CATALOG.DETAILS.STEPS.SUMMARY',
  TEAM = 'CATALOG.DETAILS.STEPS.TEAM',
  GOALS = 'CATALOG.DETAILS.STEPS.GOALS',
  CHAMPION = 'CATALOG.DETAILS.STEPS.CHAMPION',
  ORGANISATION = 'CATALOG.DETAILS.STEPS.ORGANISATION',
  QUESTIONS = 'CATALOG.DETAILS.STEPS.QUESTIONS',
  UPDATES = 'CATALOG.DETAILS.STEPS.UPDATES',
  DOCUMENTS = 'CATALOG.DETAILS.STEPS.DOCUMENTS',
}

export const PROJECT_TABS_INFO: Tab[] = [
  {
    name: ProjectTabs.SUMMARY,
    icon: 'fas fa-th fa-lg',
    link: `/${Urls.SUMMARY_TAB}`,
    id: 'project-summary-tab',
    visibility: true,
  },
  {
    name: ProjectTabs.TEAM,
    icon: 'fas fa-users fa-lg',
    link: `/${Urls.TEAM_TAB}`,
    id: 'project-team-tab',
    visibility: true,
  },
  {
    name: ProjectTabs.GOALS,
    icon: 'fas fa-bullseye fa-lg',
    link: `/${Urls.GOALS_TAB}`,
    id: 'project-goals-tab',
    visibility: true,
  },
  {
    name: ProjectTabs.CHAMPION,
    icon: 'fas fa-hand-holding-usd fa-lg',
    link: `/${Urls.CHAMPION_TAB}`,
    id: 'project-champions-tab',
    visibility: true,
  },
  {
    name: ProjectTabs.ORGANISATION,
    icon: 'fas fa-sitemap fa-lg',
    link: `/${Urls.ORGANIZATION_TAB}`,
    id: 'project-organisation-tab',
    visibility: true,
  },
  {
    name: ProjectTabs.UPDATES,
    icon: 'fas fa-sync-alt fa-lg',
    link: `/${Urls.UPDATE_TAB}`,
    id: 'project-updates-tab',
    visibility: true,
  },
  {
    name: ProjectTabs.QUESTIONS,
    icon: 'fas fa-question-circle fa-lg',
    link: `/${Urls.QUESTIONS_TAB}`,
    id: 'project-questions-tab',
    visibility: true,
  },
  {
    name: ProjectTabs.DOCUMENTS,
    icon: 'fas fa-file-pdf fa-lg',
    link: `/${Urls.DOCUMENTS_TAB}`,
    id: 'project-documents-tab',
    visibility: true,
  },
];

export const MY_GROUPS_LINKS: Tab[] = [
  {
    name: 'Owned Groups',
    link: Urls.OWNED_GROUPS,
  },
  {
    name: 'Joined Groups',
    link: Urls.JOINED_GROUPS,
  },
  // {
  //   name: 'Declined Groups',
  //   link: 'declined',
  // },
  {
    name: 'Invited To Join',
    link: Urls.INVITED_TO_JOIN,
  },
  {
    name: 'Pending Request',
    link: Urls.PENDING_REQUEST,
  },
];

export const ADMIN_ORGANISATION_TABS: Tab[] = [
  {
    name: 'ADMIN.ORGANIZATION_LIST.ALL_ORGANISATIONS',
    link: null,
  },
  {
    name: 'ADMIN.ORGANIZATION_LIST.LIVE',
    link: EntityStatus.LIVE,
  },
  {
    name: 'ADMIN.ORGANIZATION_LIST.PENDING',
    link: EntityStatus.PENDING,
  },
  {
    name: 'ADMIN.ORGANIZATION_LIST.IDLE',
    link: EntityStatus.IDLE,
  },
  {
    name: 'ADMIN.ORGANIZATION_LIST.DRAFT',
    link: EntityStatus.DRAFT,
  },
  {
    name: 'ADMIN.ORGANIZATION_LIST.DECLINED',
    link: EntityStatus.DECLINED,
  },
  {
    name: 'ADMIN.ORGANIZATION_LIST.ARCHIVED',
    link: EntityStatus.ARCHIVED,
  },
];

export const ADMIN_PROJECT_TABS: Tab[] = [
  {
    name: 'ADMIN.PROJECTS_LIST.TABS.ALL_PROJECTS',
    link: null,
  },
  {
    name: 'ADMIN.PROJECTS_LIST.TABS.LIVE',
    link: EntityStatus.LIVE,
  },
  {
    name: 'ADMIN.PROJECTS_LIST.TABS.PENDING',
    link: EntityStatus.PENDING,
  },
  {
    name: 'ADMIN.PROJECTS_LIST.TABS.IDLE',
    link: EntityStatus.IDLE,
  },
  {
    name: 'ADMIN.PROJECTS_LIST.TABS.DRAFT',
    link: EntityStatus.DRAFT,
  },
  {
    name: 'ADMIN.PROJECTS_LIST.TABS.DECLINED',
    link: EntityStatus.DECLINED,
  },
  {
    name: 'ADMIN.PROJECTS_LIST.TABS.ARCHIVED',
    link: EntityStatus.ARCHIVED,
  },
  {
    name: 'ADMIN.PROJECTS_LIST.TABS.EXPIRED',
    link: EntityStatus.EXPIRED,
  },
];

export const ADMIN_CHAMPION_TABS: Tab[] = [
  {
    name: 'ADMIN.CHAMPION_LIST.TABS.PENDING',
    link: ChampionStatus.PENDING,
    disabled: false,
  },
  {
    name: 'ADMIN.CHAMPION_LIST.TABS.LIVE',
    link: ChampionStatus.LIVE,
    disabled: false,
  },
  {
    name: 'ADMIN.CHAMPION_LIST.TABS.DECLINED',
    link: ChampionStatus.DECLINED,
    disabled: false,
  },
  {
    name: 'ADMIN.CHAMPION_LIST.TABS.ARCHIVED',
    link: ChampionStatus.ARCHIVED,
    disabled: false,
  },
  {
    name: 'ADMIN.CHAMPION_LIST.TABS.EXPIRED',
    link: ChampionStatus.EXPIRED,
    disabled: false,
  },
];

export enum UserPermissionTab {
  ALL_USERS = 'allUsersTab',
  WAITING_TO_BE_VETTED = 'waitingToBeVetted',
  PENDING_INVITATION = 'pendingInvitationTab',
  WAITING_ROOM = 'waitingRoomTab',
  INCOMPLETE_REGISTRATIONS = 'incompleteRegistrations',
  ARCHIVED = 'archived',
}

export const USER_PERMISSION_TABS: Tab[] = [
  {
    name: 'ADMIN.USERS.TABLE.ALL_USERS',
    link: UserPermissionTab.ALL_USERS,
    role: null,
    toolTip: 'ADMIN.USERS.TABLE.ALL_USERS_TOOLTIP',
  },
  {
    name: 'ADMIN.USERS.TABLE.WAITING_TO_BE_VETTED',
    link: UserPermissionTab.WAITING_TO_BE_VETTED,
    role: [Role.SUPER_ADMIN, Role.FOUNDATION_ADMIN],
    toolTip: 'ADMIN.USERS.TABLE.WAITING_TO_BE_VETTED_TOOLTIP',
  },
  {
    name: 'ADMIN.USERS.TABLE.INCOMPLETE_REGISTRATIONS',
    link: UserPermissionTab.INCOMPLETE_REGISTRATIONS,
    role: null,
    toolTip: 'ADMIN.USERS.TABLE.INCOMPLETE_REGISTRATIONS_TOOLTIP',
  },
  {
    name: 'ADMIN.USERS.TABLE.PENDING_INVITATIONS',
    link: UserPermissionTab.PENDING_INVITATION,
    role: null,
    toolTip: 'ADMIN.USERS.TABLE.PENDING_INVITATIONS_TOOLTIP',
  },
  {
    name: 'ADMIN.USERS.TABLE.WAITING_ROOM',
    link: UserPermissionTab.WAITING_ROOM,
    role: [Role.SUPER_ADMIN, Role.FOUNDATION_ADMIN],
    toolTip: 'ADMIN.USERS.TABLE.WAITING_ROOM_TOOLTIP',
  },
  {
    name: 'ADMIN.USERS.TABLE.ARCHIVED',
    link: UserPermissionTab.ARCHIVED,
    role: [Role.SUPER_ADMIN, Role.FOUNDATION_ADMIN],
    toolTip: 'ADMIN.USERS.TABLE.ARCHIVED_TOOLTIP',
  },
];

export const HOMEPAGE_TABS = [
  {
    name: 'HOME.MENU.HOME',
    privileges: { ...ModulePrivileges[Urls.GIVING], ...ModulePrivileges[Urls.CATALOG], ...ModulePrivileges[Urls.USER_ACCOUNT] },
    differentChildPrivileges: true,
    visibility: null,
    children: [
      // {
      //   name: 'HOME.MENU.ORGANIZATIONS',
      //   link: `${Urls.CATALOG}/${Urls.GIVING}/${Urls.ORGANIZATIONS}`,
      //   privileges: ModulePrivileges[Urls.GIVING],
      //   visibility: null,
      // },
      // {
      //   name: 'HOME.MENU.PROJECTS',
      //   link: `${Urls.CATALOG}/${Urls.GIVING}/${Urls.PROJECTS}`,
      //   privileges: ModulePrivileges[Urls.GIVING],
      //   visibility: null,
      // },
      // {
      //   name: 'HOME.MENU.PORTFOLIOS',
      //   link: `${Urls.CATALOG}/${Urls.GIVING}/${Urls.PORTFOLIOS}`,
      //   privileges: ModulePrivileges[Urls.GIVING],
      //   visibility: null,
      // },
      {
        name: 'HOME.MENU.MY_FAVORITES',
        link: `${Urls.SETTINGS}/${Urls.USER}/${Urls.MY_FAVORITES}`,
        privileges: ModulePrivileges[Urls.CATALOG],
        visibility: null,
      },
      {
        name: 'HOME.MENU.MY_CHAMPIONED',
        link: `${Urls.SETTINGS}/${Urls.USER}/${Urls.MY_CHAMPIONED}`,
        privileges: ModulePrivileges[Urls.MY_CHAMPIONED],
        visibility: null,
      },
      {
        name: 'HOME.MENU.CLOSE_TO_CLOSING',
        link: `${Urls.CATALOG}/${Urls.CLOSE_TO_CLOSING}`,
        privileges: { ...ModulePrivileges[Urls.CATALOG], ...ModulePrivileges[Urls.GIVING] },
        visibility: null,
      },
    ],
  },
  {
    name: 'HOME.MENU.COMMUNITY',
    link: null,
    privileges: {
      ...ModulePrivileges[Urls.INVESTORS_AND_CHAMPIONS],
      ...ModulePrivileges[Urls.PROFILES],
      ...ModulePrivileges[Urls.ACTIVITY],
      ...ModulePrivileges[Urls.GROUPS],
    },
    visibility: null,
    differentChildPrivileges: true,
    children: [
      {
        name: 'HOME.MENU.CHAMPIONS',
        link: Urls.CHAMPIONS,
        privileges: ModulePrivileges[Urls.GROUPS],
        visibility: null,
      },
      {
        name: 'HOME.MENU.ALL_GROUPS',
        link: Urls.GROUPS,
        privileges: ModulePrivileges[Urls.GROUPS],
        visibility: null,
      },
      {
        name: 'HOME.MENU.MY_GROUPS',
        link: `${Urls.GROUPS}/${Urls.MY_GROUPS}/${Urls.OWNED_GROUPS}`,
        privileges: ModulePrivileges[Urls.GROUPS],
        visibility: null,
      },
      {
        name: 'HOME.MENU.ACTIVITY',
        link: `${Urls.ACTIVITY}`,
        privileges: ModulePrivileges[Urls.ACTIVITY],
        visibility: null,
      },
    ],
  },
  {
    name: 'HOME.MENU.PROJECTS',
    link: null,
    privileges: ModulePrivileges[Urls.GIVING],
    differentChildPrivileges: false,
    visibility: null,
    order: 0,
    children: [
      {
        name: 'HOME.MENU.CHAMPIONED',
        filter: { title: FILTER_NAME.PROJECTS_CHAMPIONED, option: 'all', link: `${Urls.GIVING}/${Urls.PROJECTS}` },
      },
      {
        name: 'HOME.MENU.NEWLY_ADDED',
        visibility: true,
        filter: { title: FILTER_NAME.NEWLY_ADDED_PROJECT, option: 'DESC', link: `${Urls.GIVING}/${Urls.PROJECTS}` },
      },
      {
        name: 'HOME.MENU.CATEGORY',
        visibility: true,
        filter: { title: FILTER_NAME.PROJECTS_CATEGORY, option: 'all', link: `${Urls.GIVING}/${Urls.PROJECTS}` },
      },
      {
        name: 'HOME.MENU.REGION',
        visibility: true,
        filter: { title: FILTER_NAME.PROJECTS_REGION, option: 'all', link: `${Urls.GIVING}/${Urls.PROJECTS}` },
      },
    ],
  },
  {
    name: 'HOME.MENU.ORGANISATIONS',
    link: null,
    privileges: ModulePrivileges[Urls.GIVING],
    differentChildPrivileges: false,
    visibility: null,
    order: 1,
    children: [
      {
        name: 'HOME.MENU.CHAMPIONED',
        filter: { title: FILTER_NAME.CHARITIES_CHAMPIONED, option: 'all', link: `${Urls.GIVING}/${Urls.ORGANIZATIONS}` },
      },
      {
        name: 'HOME.MENU.NEWLY_ADDED',
        visibility: true,
        filter: { title: FILTER_NAME.NEWLY_ADDED_ORGANIZATION, option: 'DESC', link: `${Urls.GIVING}/${Urls.ORGANIZATIONS}` },
      },
      {
        name: 'HOME.MENU.CATEGORY',
        visibility: true,
        filter: { title: FILTER_NAME.CHARITIES_CATEGORY, option: 'all', link: `${Urls.GIVING}/${Urls.ORGANIZATIONS}` },
      },
      {
        name: 'HOME.MENU.REGION',
        visibility: true,
        filter: { title: FILTER_NAME.CHARITIES_REGION, option: 'all', link: `${Urls.GIVING}/${Urls.ORGANIZATIONS}` },
      },
    ],
  },
  {
    name: 'HOME.MENU.PORTFOLIOS',
    link: null,
    privileges: ModulePrivileges[Urls.GIVING],
    differentChildPrivileges: false,
    visibility: null,
    order: 2,
    children: [
      {
        name: 'HOME.MENU.CHAMPIONED',
        filter: { title: FILTER_NAME.PORTFOLIOS_CHAMPIONED, option: 'all', link: `${Urls.GIVING}/${Urls.PORTFOLIOS}` },
      },
      {
        name: 'HOME.MENU.NEWLY_ADDED',
        visibility: true,
        filter: { title: FILTER_NAME.NEWLY_ADDED_PORTFOLIO, option: 'DESC', link: `${Urls.GIVING}/${Urls.PORTFOLIOS}` },
      },
      {
        name: 'HOME.MENU.CATEGORY',
        visibility: true,
        filter: { title: FILTER_NAME.PORTFOLIO_CATEGORY, option: 'all', link: `${Urls.GIVING}/${Urls.PORTFOLIOS}` },
      },
      {
        name: 'HOME.MENU.REGION',
        visibility: true,
        filter: { title: FILTER_NAME.PORTFOLIO_REGION, option: 'all', link: `${Urls.GIVING}/${Urls.PORTFOLIOS}` },
      },
    ],
  },
];

export const GROUP_DETAILS_TABS: Tab[] = [
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.GROUP_DESCRIPTION',
    link: Urls.DESCRIPTION,
  },
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.UPDATES_AND_ANNOUNCEMENTS',
    link: Urls.ANNOUNCEMENTS_AND_UPDATES,
  },
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.PROJECTS',
    order: 0,
    link: Urls.PROJECTS_DISCUSSED,
  },
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.ORGANISATIONS',
    order: 1,
    link: Urls.ORGANISATIONS_DISCUSSED,
  },
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.PORTFOLIOS',
    link: Urls.PORTFOLIOS,
  },
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.USEFUL_DOCUMENTS',
    link: Urls.ADD_DOCUMENTS,
  },
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.MEMBERS',
    link: Urls.MEMBERS,
  },
];

export const GROUP_DETAILS_ADMIN_TABS: Tab[] = [
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.EDIT_GROUP_INFO',
    link: Urls.EDIT_GROUP,
  },
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.EDIT_ANNOUNCEMENTS',
    link: Urls.ANNOUNCEMENTS_AND_UPDATES,
  },
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.EDIT_PROJECTS',
    order: 0,
    link: Urls.PROJECTS_DISCUSSED,
  },
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.EDIT_ORGANISATIONS',
    order: 1,
    link: Urls.ORGANISATIONS_DISCUSSED,
  },
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.EDIT_PORTFOLIOS',
    link: Urls.PORTFOLIOS,
  },
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.EDIT_MEMBERS',
    link: Urls.MEMBERS,
  },
  {
    name: 'GROUPS.GROUP_DETAILS.MENU.ADD_DOCUMENTS',
    link: Urls.DOCUMENTS,
  },
];

export const BURGER_MENU_TABS: Tab[] = [
  {
    name: 'GENERAL.MENU.NAVIGATION',
    visibility: true,
    index: 0,
  },
  {
    name: 'GENERAL.MENU.SETTINGS',
    visibility: true,
    index: 1,
  },
];

export const SEARCH_RESULTS_TABS: Tab[] = [
  {
    name: 'GENERAL.MENU.ALL',
    link: null,
  },
  {
    name: 'GENERAL.MENU.ORGANISATIONS',
    link: ENTITY_TYPE.ORGANISATION,
  },
  {
    name: 'GENERAL.MENU.PROJECTS',
    link: ENTITY_TYPE.PROJECT,
  },
  {
    name: 'GENERAL.MENU.PORTFOLIOS',
    link: ENTITY_TYPE.PORTFOLIO,
  },
];
